import React from 'react';
import {
  OutlineStarIcon,
  HalfStarIcon,
  FullStarIcon,
} from './StarIcons/StarIcons';

const starType = {
  star: 'star',
  outline: 'outline',
  halfStar: 'half-star',
};

const StarType = ({ star }) => {
  if (star === 'star') {
    return <FullStarIcon />;
  }
  if (star === 'half-star') {
    return <HalfStarIcon />;
  }
  if (star === 'outline') {
    return <OutlineStarIcon />;
  }
};

const StarList = ({ starCount }) => {
  const denominator = 6;
  const numOfStars = starCount || '5';
  const slicedStars = numOfStars.split('.');
  const fullStarCount = parseInt(slicedStars[0], 10);
  const remainderStar = parseInt(slicedStars[1], 10);

  const isFiveStarRatng = fullStarCount === 5;

  const typeOfRemainingStar = () => {
    if (remainderStar !== 0 && fullStarCount !== 5 && remainderStar === 5) {
      return starType.halfStar;
    }
    return starType.outline;
  };

  // create a new array, add full stars for
  // the number of full stars in the review.
  const fullStarArray = [...new Array(fullStarCount)].map(() => starType.star);

  // (different from the array of ONLY full stars)
  // Array that has all the star types combined,
  // if not a five star review.
  let totalStars = isFiveStarRatng
    ? fullStarArray
    : [...fullStarArray, typeOfRemainingStar()];

  // if there are remaining stars left,
  // fill those spaces with outline stars.
  const remainingSlots = denominator - totalStars.length;
  if (remainingSlots > 0) {
    for (let i = 1; i < remainingSlots; i += 1) {
      totalStars.push(starType.outline);
    }
  }

  totalStars = totalStars.map((star, idx) => {
    return {
      name: `star-${idx}`,
      component: star,
    };
  });

  return (
    <>
      {totalStars.map(star => (
        <StarType key={star.name} star={star.component} />
      ))}
    </>
  );
};

export default StarList;
